<template>
  <div class="">
    {{hourShow}}:{{minuteShow}}:{{secondShow}}
  </div>
</template>

<script>

  export default {
    data() {
      return {
        hourShow:null,
        minuteShow: null,
        secondShow: null,
      }
    },
    created(){
      setInterval(this.setValue,1000);
      console.log(this.getCountdownValues('2022-01-28 2:00:00'));
    },

    methods: {
      setValue(){
        let result = this.getCountdownValues("2022-01-28 2:00:00");
        this.hourShow = result.hours;
        this.minuteShow = result.minutes;
        this.secondShow = result.seconds;
      },

      getCountdownValues: function( eventDate ) {
        let currentTimeStamp, now, parseDate, timeDifference, convertMilliseconds;
        now = new Date();
        parseDate = function( eDate ) {
          let eventDateParts = eDate.split( /\D/ ),
            dateParts = [], date, i;
          for ( i = 0; i <= eventDateParts.length; i++ ) {
            dateParts[ i ] = parseInt( eventDateParts[ i ], 10 );
          }
          date = new Date( Date.UTC( dateParts[0], dateParts[1] - 1, dateParts[2], dateParts[3], dateParts[4], dateParts[5] ) );
          return date.valueOf();
        };
        currentTimeStamp = ( new Date( Date.UTC ( now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
          now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds() ) ) ).valueOf();
        convertMilliseconds = function( ms ) {
          let d, h, m, s, padNumber;
          s = Math.floor( ms / 1000 );
          m = Math.floor( s / 60 );
          h = Math.floor( m / 60 );
          d = Math.floor( h / 24 );
          padNumber = function( n ) {
            return n < 10 ? '0' + String( n ) : n;
          };
          return {
            total: ms,
            days: d,
            hours: padNumber( h % 24 ),
            minutes: padNumber( m % 60 ),
            seconds: padNumber( s % 60 ),
          };
        };
        timeDifference = parseDate( eventDate ) - currentTimeStamp;
        return convertMilliseconds( timeDifference );
      }
    }
  }

</script>

<style scoped>

</style>